import L from "leaflet";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import './Map.css'

const MapController = ({ coordinates, zoom }: { coordinates: [number, number], zoom: number }) => {
    const map = useMap();
    useEffect(() => {
        if (coordinates) {
            map.setView(coordinates, zoom);
        }
    }, [coordinates, map]);
    return null;
};

const customIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 37],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const Maps = ({profileInfo}: any) => {
    const [coordinates, setCoordinates] = useState<any>([51.490, -0.125]);
    const [zoom, setZoom] = useState<number>(11);

    return (
        <MapContainer center={coordinates} zoom={11} className="map-container">
            <TileLayer url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
            {profileInfo?.latitude !== undefined && profileInfo?.longitude !== undefined && profileInfo?.latitude !== 0 ? (
                <>
                    <Marker position={[profileInfo.latitude, profileInfo.longitude]} icon={customIcon}>
                        <Popup>Postcode Location: {profileInfo.postCode}</Popup>
                    </Marker>
                    <MapController coordinates={[profileInfo.latitude, profileInfo.longitude]} zoom={zoom} />
                </>
            ) : 
                <div className="map-no-postcode">
                    Invalid Post Code
                </div>
            }
        </MapContainer>
    )
}

export default Maps;
