import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { useAuth } from "../auth/auth";
import { toast } from "react-toastify";
import { useNetworkState } from "react-use";

const useSignalRConnection = () => {
    const [connection, setConnection] = useState<any>(null);
    const [isConnected, setIsConnected] = useState(false);
    const { state } = useAuth();
    const network = useNetworkState();

    useEffect(() => {
        if (!network.online) {
            toast.error("You're offline. Please check your internet connection.", { position: "bottom-right" });
            return;
        }

        if (connection && connection.state !== HubConnectionState.Disconnected) {
            return;
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl('https://railresourcing.api.pace-is.co.uk/MainHub', {
                accessTokenFactory: () => state.user?.token ? state.user.token : '',
                withCredentials: true,
            })
            .withAutomaticReconnect([0, 2000, 10000, 30000])
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                console.log("SignalR Connected");
                toast.success('Online', {
                    position: "bottom-right"
                })
                setConnection(newConnection);
                setIsConnected(true)
            } catch (err) {
                console.log("Error connecting to SignalR", err);
                toast.error('Try refresh the page')
                setIsConnected(false)
                if (network.online) {
                    setTimeout(startConnection, 5000);
                };
            }
        };

        newConnection.onclose(async (error) => {
            setConnection(null)
            setIsConnected(false)
            if (network.online) {
                toast.warning("Lost connection. Trying to reconnect...", { position: "bottom-right" });
            }else {
                toast.error("Please check your internet connection.", { position: "bottom-right" });
            }
        });

        newConnection.onreconnecting((error) => {
            setIsConnected(false);
            toast.warn('Reconnecting, wait a second', {
                position: "bottom-right"
            })
        });

        newConnection.onreconnected((connectionId) => {
            setConnection(newConnection)
            setIsConnected(true)
            toast.info('Reconnected', {
                position: "bottom-right"
            })
            console.log("SignalR reconnected. Connection ID:", connectionId);
        });

        newConnection.serverTimeoutInMilliseconds = 60000;

        startConnection();

        return () => {
            if (newConnection.state === HubConnectionState.Connected) {
                newConnection.stop();
                setIsConnected(false);
            }
        };
    }, [state.user?.token, network.online]);

    return {connection, isConnected};
};

export default useSignalRConnection;
