import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../../auth/auth";
import { addTicketDocuments, deleteTicketDocument, getUserTicketDocuments } from "../../../../../api/apiCall";
import { Button, Checkbox, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileImage, faFilePdf, faFileWord, faPlus } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { toast } from "react-toastify";
import './TicketDocuments.css'

const TicketDocuments = () => {
  const {state} = useAuth();
  const location = useLocation();
  const [documents, setDocuments] = useState<any>([])
  const { userTicketId, name, surname, ticketName } = location.state || {};
  const fileInputRef = useRef<any>(null);
  const [selectedDocument, setSelectedDocument] = useState<any>(null)

  const [, setOpen] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const getTicketDocuments = async () => {
    const response = await getUserTicketDocuments(state.user?.token, userTicketId);
    setDocuments(response?.data);
  }

  const generateBlobUrl = (base64Data: any, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    // Convert base64 string to a byte array
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
  
    const byteArray = new Blob(byteArrays, { type: contentType });
    return URL.createObjectURL(byteArray);
  };

  const bytesToMB = (bytes: number) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  const handleImageUpload = async (event: any) => {
    const files = event.target.files;    
    if (files && files.length > 0) {
      const fileArray = Array.from(files);
      
      try {
        const response = await addTicketDocuments(state.user?.token, {
          userTicketId: userTicketId,
          documents: fileArray
        });
        if(response?.status === 200) {
          getTicketDocuments();
          toast.success('Document uploaded successfully');
        }
      } catch (error) {
         console.error('Error uploading files:', error);
      }
    }
  };

  const handleDelete = async (ticketDocumentId: any) => {
    const response = await deleteTicketDocument(state.user?.token, ticketDocumentId);
    if(response?.status === 200) {
      getTicketDocuments();
      toast.error('Document deleted successfully');
    }
  }

  useEffect(() => {
    getTicketDocuments();
  }, []);

  const onChange = (e: any, document: any) => {
    if (e.target.checked) {
      setSelectedDocument(document);
    } else {
      setSelectedDocument(null);
    }
  };

  return (
    <>
      <header className="header-logger" style={{margin: 25, borderBottom: 'none'}}>
        <h2>{ticketName} Documents
          <h4 className="uk-time">{name} {surname} </h4>
        </h2>
        <div>
          {selectedDocument && 
            <Button danger type="primary"
              onClick={() => {
                setOpen(true);
                Modal.confirm({
                  title: `Are you sure you want to delete ${selectedDocument.documentName}?`,
                  onOk: () => handleDelete(selectedDocument.id),
                  okButtonProps: { danger: true },
                  okText: 'YES',
                  cancelText: 'NO',
                  centered: true
                });
              }}
            >
              Delete
            </Button>
          }
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleButtonClick}
            style={{margin: 20}}>
              Add New
          </Button>
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff,.xls,.xlsx,.doc,.docx,.pdf"
            hidden
            multiple
            ref={fileInputRef}
            onChange={handleImageUpload}
          />
        </div>
      </header>

      {documents.length > 0 ? (
        <>
          <table className="location-logger-table" style={{width: '90%', margin: '0 auto'}}>
            <thead>
              <tr>
                <th className="left-align"></th>
                <th className="left-align">Type</th>
                <th>Name</th>
                <th>Added Date</th>
                <th>File Size</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc: any) => (
                <tr key={doc.id}>
                  <td>
                    <Checkbox
                      onChange={(e) => onChange(e, doc)}
                      checked={selectedDocument?.id === doc.id}
                    ></Checkbox>
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <div>
                      {doc.contentType === "image/png" || doc.contentType === 'image/jpeg' ? (
                        <FontAwesomeIcon icon={faFileImage} size="2x" color="gray" />
                      ) : doc.contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                        <FontAwesomeIcon icon={faFileExcel} color="#107c41" size="2x" />
                      ) : doc.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <FontAwesomeIcon icon={faFileWord} color="#185abd" size="2xl" />
                      ) : doc.contentType === "application/pdf" ? (
                        <FontAwesomeIcon icon={faFilePdf} color="#e84133" size="2xl" />
                      ) : (
                        <FontAwesomeIcon icon={faFileImage} size="2x" color="gray" />
                      )}
                    </div>
                  </td>
                  <td>
                    <a 
                      href={generateBlobUrl(doc.data, doc.contentType)} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{textDecoration: 'none', color: 'black'}}
                    >
                      {doc.documentName}
                    </a>
                  </td>
                  <td>{format(new Date(doc.createdDate), "MMMM d, yyyy")}</td>
                  <td>{bytesToMB(doc.size)} MB</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="text-no-document">
          No documents available for this property.
        </div>
      )}
    </>
  )
}

export default TicketDocuments;