/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useAuth } from '../../auth/auth';
import Profile from './Profile/Profile';
function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProfileTab = () => {
  const {state} = useAuth();
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  };

  return (
    // <Box pt={2} sx={{backgroundColor: '#f2f5fc'}} >
    //   <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    //     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
    //       <Tab sx={{"&.Mui-selected": {color: "black"}}} label="Profile" {...a11yProps(0)} />
    //       <Tab sx={{"&.Mui-selected": {color: "black"}}} label="Gradings Rate" {...a11yProps(1)} />
    //       <Tab sx={{"&.Mui-selected": {color: "black"}}} label="Basic Rates" {...a11yProps(2)} />
    //     </Tabs>
    //   </Box>

    //   <CustomTabPanel value={value} index={0}>
    //     <Profile />
    //   </CustomTabPanel>

    //   <CustomTabPanel value={value} index={1}>
    //     <Gradings />
    //   </CustomTabPanel>

    //   <CustomTabPanel value={value} index={2}>
    //     <BasicRatesForm />
    //   </CustomTabPanel>
    // </Box>
    <Box pt={2} sx={{backgroundColor: '#f2f5fc'}}>
      <CustomTabPanel value={value} index={0}>
        <Profile />
      </CustomTabPanel>
    </Box>
  );
}

export default ProfileTab;
