import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    ListItem,
    MenuItem,
    TextField
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Modal } from "antd";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { assignCompetencyToUser, getAllCompetenecies, removeCompetencyFromUser } from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import EditCompetency from "./EditCompetency/EditCompetency";
import './Competencies.css';

const Competencies = ({profileInfo, getWorkerInfo}: any) => {
    const {state} = useAuth();
    const [isModalCompetencyOpen, setIsModalCompetencyOpen] = useState(false);
    const [isModalEditCompetencyOpen, setIsModalEditCompetencyOpen] = useState(false);
    const [competencies, setCompetencies] = useState<any>([]);
    const [selectedCompetency, setSelectedCompetency] = useState<any>();
    const [expDate, setExpDate] = useState<any>(dayjs());
    const [skillStatus, setSkillStatus] = useState<any>(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [id, setId] = useState<any>('');
    const [, setOpen] = useState(false);

    useEffect(() => {
        getAllCompetenecies(state.user?.token).then(setCompetencies);
    }, []);

    const handleCancelCompetency = () => {
        setIsModalCompetencyOpen(false);
    };

    const handleCancelEditCompetencyModal = () => {
        setSelectedCompetency('')
        setIsModalEditCompetencyOpen(false);
    };

    const addCompetency = async () => {
        const response = await assignCompetencyToUser(state.user?.token, {
            userId: profileInfo.id,
            competencyId: selectedCompetency.id,
            expDate: format(expDate.$d, 'yyyy-MM-dd'),
            skillStatus: skillStatus
        });

        if(response) {
            setIsModalCompetencyOpen(false);
            toast.success('Competency added successfully');
            getWorkerInfo();
        }
    };

    const getCompetencyStatus = (skillStatus: any) => {
        switch(skillStatus) {
            case 0: 
                return 'Pending'
            case 1:
                return 'Approved'
            case 2:
                return 'Denied'
            case 3:
                return 'Not Verified'
        }
    }

    const handleDelete = async (id: any) => {
        const response = await removeCompetencyFromUser(state.user?.token, id);
        if(response) {
            getWorkerInfo();
        } else {
            toast.error('Something went wrong!')
        }
    };

    const competenciesColumns: any = [
        {
            field: "skillName",
            headerName: "Competency",
            flex: 2,
            renderCell: (params: any) => (
                <div>{params.row.skill.skillName}</div>
            ),
        },
        {
            field: "expDate",
            headerName: "Expiration Date",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            cellClassName: (params: any) => {
                const today = new Date();
                const expirationDate = new Date(params.value);
                return expirationDate <= today ? "expired" : "approved";
            },
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.expDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "skillStatus",
            headerName: "Status",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => (
                <div>
                    {getCompetencyStatus(params.row.skillStatus)}
                </div>
            ),
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => <Button variant="contained"
             style={{backgroundColor: 'gray', boxShadow: 'none'}}
             onClick={() => {
                setId(params.id);
                setSelectedCompetency(params.row.skill)
                setExpDate(dayjs(params.row.expDate))
                setSkillStatus(params.row.skillStatus)
                setIsModalEditCompetencyOpen(true)
            }}
             >Edit</Button>
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: `Are you sure you want to delete ${params.row.skill.skillName}?`,
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const filteredCompetencies = profileInfo?.competencies
    ? profileInfo.competencies.filter((exp: any) =>
        exp.skill.skillName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

    return (
        <>
            <Box display="flex" justifyContent="right" alignItems="center" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: 170, boxShadow: "none" }}
                    onClick={() => { setIsModalCompetencyOpen(true); }}>
                        Add Competency
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <h2>Competencies</h2>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    style={{width: 300}}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <Box>
                <DataGrid
                    rows={filteredCompetencies}
                    columns={competenciesColumns}
                    style={{ height: 474 }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15.5,
                        }
                    }}
                />
            </Box>
            <Modal
                title={`Add competency for ${profileInfo?.name} ${profileInfo?.surname}`}
                onCancel={handleCancelCompetency}
                open={isModalCompetencyOpen}
                footer={[
                    <div className="center-button-div">
                        <Button variant="contained" sx={{ boxShadow: 'none', mb: 2 }} onClick={addCompetency}>
                            Add Competency
                        </Button>
                    </div>
                ]}>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr" }} gap={4} mt={4}>
                    <Autocomplete
                        size="small"
                        options={competencies}
                        onChange={(_, newValue) => {setSelectedCompetency(newValue)}}
                        getOptionLabel={(option: any) => option.skillName}
                        renderOption={(props, option) => (
                            <ListItem {...props} key={option.skillName}>
                              {option.skillName}
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Competency" placeholder="Select Competency" />
                        )}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                slotProps={{textField: { size: 'small', fullWidth: true }}}
                                label="Experation Date"
                                value={expDate}
                                onChange={(value) => {setExpDate(value)}}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                     <TextField
                        select
                        id="demo-select-small"
                        size="small"
                        label="Status"
                        sx={{mb: 4}}
                        value={skillStatus}
                        onChange={(e) => {setSkillStatus(e.target.value)}}
                    >
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Approved</MenuItem>
                        <MenuItem value={2}>Denied</MenuItem>
                        <MenuItem value={3}>Not Verified</MenuItem>
                    </TextField>
                </Box>
            </Modal>
            <Modal
                title={`Edit Competency for ${profileInfo?.name} ${profileInfo?.surname}`}
                onCancel={handleCancelEditCompetencyModal}
                open={isModalEditCompetencyOpen}
                footer={[
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {/* <Button variant="contained" sx={{ boxShadow: 'none', mb: 2 }} onClick={addTicket}>
                            Edit Ticket
                        </Button> */}
                    </div>
                ]}>
                    <EditCompetency
                        id={id}
                        profileInfo={profileInfo}
                        competencies={competencies}
                        selectedCompetency={selectedCompetency}
                        setSelectedCompetency={setSelectedCompetency}
                        expDate={expDate}
                        setExpDate={setExpDate}
                        skillStatus={skillStatus}
                        setSkillStatus={setSkillStatus}
                        setIsModalEditCompetencyOpen={setIsModalEditCompetencyOpen}
                        getWorkerInfo={getWorkerInfo}
                    />
            </Modal>
        </>
    )
}

export default Competencies;
