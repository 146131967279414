import React from "react";
import { Autocomplete, Box, Button, ListItem, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useAuth } from "../../../../../auth/auth";
import { editUserTicket } from "../../../../../api/apiCall";
import { toast } from "react-toastify";

const EditTicket = ({
    profileInfo,
    id,
    tickets,
    selectedTicket,
    setSelectedTicket,
    expDate,
    setExpDate,
    ticketStatus,
    setTicketStatus,
    setIsModalEditTicketOpen,
    getWorkerInfo,
}: any) => {
    const {state} = useAuth();

    const editTicket = async () => {
        const response = await editUserTicket(state.user?.token, {
            userId: profileInfo.id,
            id: id,
            ticketId: selectedTicket.id,
            expDate: expDate,
            ticketStatus: ticketStatus,
        })
        if(response) {
            setIsModalEditTicketOpen(false);
            toast.success('Ticket added successfully');
            getWorkerInfo();
        }
    }
        
    return (
        <Box display="grid" gridTemplateColumns={{xs: "1fr", md: "1fr"}} gap={4} mt={4}>
            <Autocomplete
                size="small"
                disabled
                options={tickets}
                value={tickets.find((ticket: any) => ticket.id === selectedTicket) || selectedTicket}
                onChange={(_, newValue) => {
                    setSelectedTicket(newValue)
                }}
                getOptionLabel={(option: any) => option.ticketName}
                renderOption={(props, option) => (
                    <ListItem {...props} key={option.ticketName}>
                      {option.ticketName}
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Ticket" placeholder="Select Ticket" />
                )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        label="Expiration Date"
                        value={expDate ? dayjs(expDate) : null}
                        onChange={(value) => {
                            setExpDate(value ? value.toISOString() : null);
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <TextField
                select
                value={ticketStatus}
                id="demo-select-small"
                size="small"
                label="Status"
                sx={{mb: 2}}
                onChange={(e: any) => {setTicketStatus(e.target.value)}}
            >
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>Approved</MenuItem>
                <MenuItem value={2}>Denied</MenuItem>
                <MenuItem value={3}>Not Verified</MenuItem>
            </TextField>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button variant="contained" sx={{boxShadow: 'none'}} onClick={editTicket}>
                    Edit Ticket
                </Button>
            </div>
        </Box>
    )
}

export default EditTicket;