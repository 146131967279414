/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAuth } from "../../../../../auth/auth";
import { getProjects, GetRegions } from "../../../../../api/apiCall";
import { useForm } from "react-hook-form";
import './AddShift.css';

type Props = {
  week: any
  onSubmit: any
  closeModal: any
  dateOfTheShift: any
  currentCell: any
  formDate: any
  loading: any
}

type FormValues = {
  week: 0
  projectId: string
  rowIndex: number
  columnIndex: number
  whatsAppName: string
  region: string
  jobDate: string
  location: string
  postCode: string
  jobDescription: string
  shiftType: number
  requiredWorkers: number
  startTime: any
  endTime: any
}

const AddShift = ({
  week,
  onSubmit,
  closeModal,
  dateOfTheShift,
  currentCell,
  formDate,
  loading
}: Props) => {
  const {state} = useAuth();
  const [projects, setProjects] = useState([]);
  const [regions, setRegions] = useState<any>([]);
  const [selectedTypeOfWork, setSelectedTypeOfWork] = useState<string>("");
  const [selectedShiftType, setSelectedShiftType] = useState<number>((currentCell.colIndex === 0 || currentCell.colIndex === 1) ? 2 : 0);

  const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormValues>({
    defaultValues: {
      projectId: "",
      week: week,
      rowIndex: currentCell.rowIndex,
      columnIndex: currentCell.colIndex,
      location: "",
      region: selectedTypeOfWork,
      jobDate: formDate,
      shiftType: selectedShiftType,
      jobDescription: "",
      requiredWorkers: 1,
      postCode: "",
      whatsAppName: "",
      startTime: "00:00",
      endTime: "00:00",
    }
  })

  const getAllProjects = async () => {
    const response = await getProjects(state.user?.token);
    setProjects(response);
  }

  const getAllRegions = async () => {
    const response = await GetRegions(state.user?.token);
    setRegions(response);
  }

  useEffect(() => {
    getAllProjects();
    getAllRegions();
  }, []);

  return (
    <form>
      <div className="center-content">
        <TextField
          label="Week"
          type="number"
          size="small"
          disabled
          {...register("week")}
          style={{width: "20%", margin: 10}}
        />
        <TextField
          label="WhatsApp Name"
          type="text"
          size="small"
          error={!!errors.whatsAppName}
          style={{margin: 10, width: '30%'}}
          {...register("whatsAppName",{
            required: "Whats app Name is required"
          })}
        />
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          {...register("projectId", {
            required: "Project is required"
          })}
          onChange={(event: any) => {
            const selectedProjectId = event.target.value;
            const selectedProject: any = projects.find(
              (project: any) => project.id === selectedProjectId
            );
            if (selectedProject) {
              setValue("region", selectedProject.postCode)
              setSelectedTypeOfWork(selectedProject.postCode);
            }
          }}
          error={!!errors.projectId}
          defaultValue={1}
          size="small"
          style={{height: 40, margin: 10, width: '30%'}}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
        >
          <MenuItem disabled value={1}>Project</MenuItem>
          {projects.map((project: any) =>
            <MenuItem key={project.id} value={project.id}>
              {project.company.companyName} - {project.projectName}
            </MenuItem>
          )}
        </Select>
      </div>
      <div className="center-content">
        <TextField
          select
          id="demo-select-small"
          size="small"
          label="Type of Work"
          value={selectedTypeOfWork}
          style={{ margin: 10, width: '22.5%' }}
          {...register("region")}
          onChange={(e) => setSelectedTypeOfWork(e.target.value)}
        >
          <MenuItem value={"N/A"}>N/A</MenuItem>
          <MenuItem value={'Price Work'}>Price Work</MenuItem>
          <MenuItem value={'Labour'}>Labour</MenuItem>
          <MenuItem value={'X Other'}>X Other</MenuItem>
        </TextField>
        <TextField
          label="Location"
          type="text"
          size="small"
          {...register("location")}
          error={!!errors.location}
          style={{width: "20%", margin: 10}}
        />
        <TextField
          label="Post Code"
          type="text"
          size="small"
          error={!!errors.postCode}
          {...register("postCode")}
          style={{width: "20%", margin: 10}}
        />
        <TextField
          label="Start Time"
          type="time"
          size="small"
          {...register("startTime")}
          onChange={(event: any) => {
            if(currentCell.colIndex === 0 || currentCell.colIndex === 1) {
              return;
            }else {
              const hour = parseInt(event.target.value.slice(0, 2), 10);
              setValue('shiftType', hour >= 6 && hour < 18 ? 0 : 1)
              setSelectedShiftType(hour >= 6 && hour < 18 ? 0 : 1)
            }
          }}  
          style={{ width: "15.5%", margin: 10 }}
        />
      </div>
      <div className="center-content">
        <TextField
          label="End Time"
          type="time"
          size="small"
          {...register("endTime")}
          style={{ width: "15.5%", margin: 10 }}
        />
        <TextField
          label="Date"
          id="outlined-basic"
          variant="outlined"
          type="text"
          value={dateOfTheShift}
          {...register("jobDate")}
          disabled
          size="small"
          style={{margin: 10, width: "20%"}}
        />
        <TextField
          label="Required Workers"
          type="number"
          {...register("requiredWorkers", {
            required: "Required Workers is required"
          })}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
          error={!!errors.requiredWorkers}
          size="small"
          style={{margin: 10, width: "22.5%"}}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={selectedShiftType}
          error={!!errors.shiftType}
          {...register("shiftType", {
            required: "Shift Type Workers is required"
          })}
          onChange={(e: any) => setSelectedShiftType(e.target.value)}
          style={{height: 40, marginTop: 10, margin: 10, width: '20%'}}
        >
          <MenuItem value={0}>Day Shift</MenuItem>
          <MenuItem value={1}>Night Shift</MenuItem>
          <MenuItem value={2}>Weekend</MenuItem>
        </Select>
      </div>
      <div className="center-content">
        <TextField
          multiline
          rows={4}
          error={!!errors.jobDescription}
          label="Description"
          {...register("jobDescription")}
          style={{width: "84%", margin: 10}}
        />
      </div>
      <div className="center-content margin">
        <Button
          variant="outlined"
          id="save-button"
          size="large"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          {loading? <CircularProgress size={20} style={{color: 'white'}} />: 'Save'}
        </Button>
        <Button
          variant="outlined"
          onClick={closeModal}
          size="large"
          disabled={loading}
          style={{width: "25%"}}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

export default AddShift;
