/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import MUIRichTextEditor from "mui-rte";
import { useAuth } from "../../../../../auth/auth";
import { saveJobNotes } from "../../../../../api/apiCall";
import { toast } from "react-toastify";
import "./TextEditor.css"
import { Button } from "@mui/material";

const TextEditor = ({id, data, getEditJobModal, connection, week, year}: any) => {
    const {state} = useAuth();
    const [notesJson, setNotesJson] = useState(data.notesJson === "null" ? null : data.notesJson)
    const editorRef = useRef<any>(null);

    const handleSave = async (editorData?: any) => {
        const rawData = editorData || (editorRef.current ? await editorRef.current.save() : null);
        if (!rawData) return;

        const parsedData = JSON.parse(rawData);
        
        let text = parsedData.blocks.map((block: any) => block.text.trim()).join(" ");
        const hasContent = parsedData.blocks.some((block: any) => block.text.trim() !== "");

        const finalData = hasContent ? rawData : null;
    
        const response = await saveJobNotes(state.user?.token, id, text || null, finalData);
        if (response) {
            connection.invoke("EditJobNotesJson", `${week}-${year}`, id, finalData);
            toast.success("Added Notes successfully");
        } else {
            toast.error("Something went wrong!");
        }
        getEditJobModal();
    };

    useEffect(() => {
        if(connection) {
            connection.on('EditJobNotesJson', (jobID: any, notesJson: any)=>{
                if(data.id === jobID) {
                    setNotesJson(notesJson)
                }
            })

            return () => {
                connection.off('EditJobNotesJson')
            }
        }
    }, [connection])

    return (
        <>
            <div className="center-text-editor">
                <div className="border-style">
                    <MUIRichTextEditor
                        ref={editorRef}
                        label="Start typing..."
                        inlineToolbar
                        onSave={handleSave}
                        defaultValue={notesJson}
                    />
                </div>
            </div>
            <Button
                variant="contained" 
                style={{boxShadow: "none", margin: '0 auto', marginTop: 20, display: 'flex'}} 
                onClick={() => handleSave()}
            >
                Save Notes
            </Button>
        </>
    );
};

export default TextEditor;
