import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth/auth";
import { Autocomplete, Box, Button, ListItem, TextField } from "@mui/material";
import { addAuthorizedDrivers, getAuthorizedDrivers, GetWorkersSuggestions, removeAuthorizedDriver } from "../../api/apiCall";
import { DataGrid } from "@mui/x-data-grid";
import './Drivers.css';
import { toast } from "react-toastify";
import { Modal } from "antd";

const Drivers = () => {
  const {state} = useAuth();
  const [suggestions, setSuggestions] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [workers, setWorkers] = useState([])
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [, setOpen] = useState(false);
  const typingDelay = 100;
  let typingTimer: any;

  const getWorkers = async () => {
    const response = await getAuthorizedDrivers(state.user?.token);
    setWorkers(response);
  }
  
  const handleAddWorker = async () => {
    const response = await addAuthorizedDrivers(state.user?.token, id);
    if(response) {
      toast.success('Authorized Driver added sucessfully');
      getWorkers();
    } else {
      toast.error('Something went wrong')
    }
  }

  const handleRemoveWorker = async (userId: any) => {
    const response = await removeAuthorizedDriver(state.user?.token, userId);
    if(response) {
      toast.error('Authorized Driver removed sucessfully');
      getWorkers();
    }
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await GetWorkersSuggestions(
          state.user?.token,
          inputValue
        );

        if (response) {
          setSuggestions(response.data);
        } else {
          console.error("Error fetching suggestions");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    if (inputValue) {
      typingTimer = setTimeout(fetchSuggestions, typingDelay);
    }
  
    return () => clearTimeout(typingTimer);
  }, [inputValue]);
  
  const handleNameChange = async (newName: any) => {
    setId(newName.userId);
    setName(newName.fullName)
  }

  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 0.6,
    },
    {
      field: "pts",
      headerName: "PTS",
      flex: 0.6,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.6,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.8,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 0.4,
      renderCell: (params: any) => (
        <Button
          variant="contained"
          color="error"
          style={{backgroundColor: 'gray', boxShadow: ' none'}}
        >
          Details
        </Button>
      )
    },
    {
      field: "remove",
      headerName: "Remove",
      flex: 0.4,
        renderCell: (params: any) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setOpen(true);
              Modal.confirm({
                title: `Are you sure you want to remove ${params.row.fullName}? `,
                onOk: () => handleRemoveWorker(params.id),
                okButtonProps: { danger: true },
                okText: 'YES',
                cancelText: 'NO',
                width: 470,
                centered: true
              });
          }}>
            Remove
          </Button>
        )
    },
  ];

  useEffect(() => {
    getWorkers();
  }, []);

  return (
    <>
      <div className="fullname-label">
        <div className="fullname-label-text">Full Name:</div>
        <Autocomplete
          size="small"
          options={suggestions || []}
          disableClearable
          style={{ margin: 5, width: '350px' }}
          getOptionLabel={(option: any) => `${option?.fullName || ""}`}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleNameChange(newValue);
          }}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.fullName}>
              {option.fullName} - {option.pts}
            </ListItem>
          )}
          renderInput={(params) =>
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (event) => {
                  if (event.key === ' ') {
                    event.stopPropagation();
                  }
                },
              }}
            />
          }
        />
        <Button
          variant="contained"
          style={{ boxShadow: 'none', height: 40, margin: 5, marginLeft: 30}}
          onClick={handleAddWorker}
        >
          Add Driver
        </Button>
      </div>
      <Box sx={{height: 700, width: '85%', margin: '0 auto'}} marginTop={3}>
        <DataGrid
          rows={workers}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#fff',
              color: '#000',
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            '& .MuiDataGrid-footerContainer': {
              color: '#fff',
            },
            '& .MuiDataGrid-row': {
              '&:nth-of-type(odd)': {
                backgroundColor: '#f5f5f5',
              },
            },
          }}
        />
      </Box>
    </>
  )
}

export default Drivers;
