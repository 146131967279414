import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Tab, Tabs, TextField } from "@mui/material";
import { useAuth } from "../../../auth/auth";
import Header from "../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import './GenerateTimesheet.css'
import { generateColasTimesheet, generateColasWeekendTimesheet } from "../../../api/apiCall";
import PropTypes from 'prop-types';

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GenerateMidweekTimesheet = () => {
  const {state} = useAuth();
  const [date, setDate] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
  const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
  const [loading, setLoading] = useState(false);
  const [labourOrderNumber, setLabourOrderNumber] = useState()
  const [value, setValue] = useState(0)

  const generateTimesheet = async () => {
    try {
      setLoading(true)
      const response = await generateColasTimesheet(state.user?.token, week, year, labourOrderNumber);
      if(response){
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const generateWeekendTimesheet = async () => {
    try {
      setLoading(true)
      const response = await generateColasWeekendTimesheet(state.user?.token, week, year, labourOrderNumber);
      if(response){
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  };

  useEffect(() => {
    setSearchParams({ week, year });
  }, [date]);

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <div className="form-container">
        <div className="timesheet-form">
          <Box>
            <Box sx={{borderBottom: 1, borderColor: 'divider', m: 2}}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab label="Weekend" {...a11yProps(0)} style={{fontSize: 15}} />
                <Tab label="Midweek" {...a11yProps(1)} style={{fontSize: 15}} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <div className="form-container-div">
                <h2 className="h2-element">Colas Weekend Timesheet</h2>
                <p className="p-element">Generate Timesheet for Week {week}</p>
                <TextField
                  variant="outlined"
                  label="Labour Order Number"
                  placeholder="Enter Labour Order Number"
                  value={labourOrderNumber}
                  onChange={(e: any) => setLabourOrderNumber(e.target.value)}
                  InputProps={{
                    style: {backgroundColor: "#f9f9f9"},
                  }}
                />
                <Button
                  variant="contained"
                  disabled={loading}
                  size="large"
                  style={{boxShadow: "none", marginTop: 25}}
                  className="export-button"
                  onClick={generateWeekendTimesheet}
                >
                  {loading ? (
                    <CircularProgress size={25} style={{ color: "white" }} />
                  ) : (
                    "Export Timesheet"
                  )}
                </Button>
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <div className="form-container-div">
                <h2 className="h2-element">Colas Midweek Timesheet</h2>
                <p className="p-element">Generate Timesheet for Week {week}</p>
                <TextField
                  variant="outlined"
                  label="Labour Order Number"
                  placeholder="Enter Labour Order Number"
                  value={labourOrderNumber}
                  onChange={(e: any) => setLabourOrderNumber(e.target.value)}
                  InputProps={{
                    style: {backgroundColor: "#fafafa"},
                  }}
                />
                <Button
                  variant="contained"
                  disabled={loading}
                  size="large"
                  style={{boxShadow: "none", marginTop: 25}}
                  className="export-button"
                  onClick={generateTimesheet}
                >
                  {loading ? (
                    <CircularProgress size={25} style={{ color: "white" }} />
                  ) : (
                    "Export Timesheet"
                  )}
                </Button>
              </div>
            </CustomTabPanel>

          </Box>
        </div> 
      </div>
    </>
  )
}

export default GenerateMidweekTimesheet;
