import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getWorkerProfile, updateProfilePhoto } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import Competencies from "./Competencies/Competencies";
import Experiences from "./Experiences/Experiences";
import Ticket from "./Tickets/Tickets";
import Maps from "./Map/Map";

const Profile = () => {
    const {state} = useAuth();
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [image, setImage] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const handleImageUpload = async (event: any) => {
      const file = event.target.files[0];
      if (file) {
        const response = await updateProfilePhoto(state.user?.token, profileInfo.id, file);
        if(response) {
            toast.success('Profile updated successfully');
        }
        setImage(file);
      }
    };

    const handleEdit = async () => {
        navigate('/pace-team/resourcing/edit-worker', {
            state: { workerId: profileInfo?.id }});
    };

    const getWorkerInfo = async () => {
        setLoading(true);
        try {
            const response = await getWorkerProfile(state.user?.token, searchParams.get('workerId'));
            setProfileInfo(response);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getWorkerInfo();
    }, [])

    return (
        <>
            <Box display="grid" gridTemplateColumns={{xs: "1fr", md: "1fr 1fr 1fr 1fr"}} gap={2} mt={7}
                sx={{
                    backgroundColor: "#fff",
                    padding: "50px",
                    margin: "auto",
                    background: "#fff",
                    marginTop: 5,
                    border: '1px solid rgb(236, 236, 236)',
                }}
            >
                <Grid item>
                    <Typography variant="h6" fontWeight="bold" mb={4}>
                        Worker's Details
                    </Typography>
                    <Box position="relative" display="inline-block">
                        <Avatar
                            sx={{ width: 160, height: 160 }}
                            src={image ? URL.createObjectURL(image) : ("data:image/png;base64," + profileInfo?.picture)}
                        />
                        <IconButton
                            component="label"
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                bgcolor: "background.paper",
                                borderRadius: "50%",
                                padding: "5px",
                            }}
                        >
                            <EditIcon />
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleImageUpload}
                            />
                        </IconButton>
                    </Box>
                    <Box mt={4}>
                        <Typography variant="body1">
                            <strong>Username</strong> {profileInfo?.username}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                            <strong>Created Date: </strong> {format(new Date(profileInfo?.createdDate || Date.now()), 'dd/MM/yyyy')}
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{boxShadow: 'none', width: '100px', marginTop: 4}}
                            onClick={handleEdit}
                        >
                            Edit
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" mb={4} mt={4}>
                        <strong>Name</strong><Typography>{profileInfo?.name} {profileInfo?.surname}</Typography> 
                    </Typography>
                    <Typography variant="body1" mb={4}>
                        <strong>Sentinel Number</strong>
                        <Typography color={"orangered"} fontWeight={"bold"}>{profileInfo?.pts}</Typography>
                    </Typography>
                    <Typography variant="body1">
                        <strong>Phone Number</strong><Typography>{profileInfo?.phoneNumber}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" mb={4} mt={4}>
                        <strong>Email</strong><Typography>{profileInfo?.email}</Typography> 
                    </Typography>
                    <Typography variant="body1" mb={4}>
                        <strong>Post Code</strong><Typography>{profileInfo?.postCode}</Typography>
                    </Typography>
                    <Typography variant="body1" mb={4}>
                        <strong>Location</strong><Typography>{profileInfo?.location}</Typography> 
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" mb={4} mt={4}>
                        <strong>Driver</strong>
                            <Typography>
                                {profileInfo?.driver?
                                    <FontAwesomeIcon icon={faCheck} color="green" size="lg" /> : 
                                    <FontAwesomeIcon icon={faX} color="red" size="lg" />
                                }
                            </Typography>
                    </Typography>
                    <Typography variant="body1" mb={4} mt={4}>
                        <strong>SUFFIX</strong><Typography>{profileInfo?.suffix}</Typography>
                    </Typography>
                    <Typography variant="body1" mb={4}>
                        <strong>Notes</strong><Typography>{profileInfo?.notes}</Typography> 
                    </Typography>
                </Grid>
            </Box>
            <Box
                sx={{
                    margin: "auto",
                    padding: 4,
                    background: "#fff",
                    marginTop: 5,
                    border: '1px solid rgb(236, 236, 236)'
                }}>
                <Competencies profileInfo={profileInfo} getWorkerInfo={getWorkerInfo} />
            </Box>
            <Box
                sx={{
                    margin: "auto",
                    padding: 4,
                    background: "#fff",
                    marginTop: 5,
                    border: '1px solid rgb(236, 236, 236)'
                }}>
                <Ticket profileInfo={profileInfo} getWorkerInfo={getWorkerInfo} />
            </Box>
            <Box
                sx={{
                    margin: "auto",
                    padding: 4,
                    background: "#fff",
                    marginTop: 5,
                    border: '1px solid rgb(236, 236, 236)'
                }}>
                <Experiences profileInfo={profileInfo} getWorkerInfo={getWorkerInfo} />
            </Box>
            <Box
                sx={{
                    margin: "auto",
                    padding: 4,
                    background: "#fff",
                    marginTop: 5,
                    border: '1px solid rgb(236, 236, 236)'
                }}>
                <Box>
                    <h2>Map Location</h2>
                </Box>
                <Maps profileInfo={profileInfo}/>
            </Box>
        </>
    );
}

export default Profile;
