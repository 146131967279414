/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, TextField } from "@mui/material";
import { searchWorkers } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import './StaffDetails.css'
import { useNavigate } from "react-router-dom";

const StaffDetails = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  const [workers, setWorkers] = useState<any>([]);

  const {register, handleSubmit} = useForm<any>({
    defaultValues: {
      name: "",
      pts: "",
      phoneNumber: "",
      notes: "",
      pageNumber: 0
    }
  })

  const getWorkers = async (data: any) => {
    const response: any = await searchWorkers(state.user?.token, data);
    setWorkers(response)
  }

  const handleClick = (userId: any) => {
    navigate(`/pace-team/resourcing/profile?workerId=${userId}`);
  };

  const handleCreateWorker = () => {
    navigate('/pace-team/resourcing/create-worker');
  };

  const columns: any = [
    {
      field: "name",
      headerName: "Full Name",
      width: 250,
      renderCell: (params: any) => (
        <div
          onClick={() => handleClick(params.row.id)}
          style={{cursor: "pointer"}}
        >
          {params.row.name} {params.row.surname}
        </div>
      ),
    },
    {
      field: "pts",
      headerName: "PTS",
      width: 220,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 220,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
    },
    {
      field: "driver",
      headerName: "Driver",
      width: 90,
      headerAlign: "center",
      align: 'center',
      renderCell: (params: any) => (
        <div>
          {params.row.driver?
            (<FontAwesomeIcon icon={faCheck} size={'lg'} color={'green'} />)
            : (<FontAwesomeIcon icon={faX} size={'sm'} color={'red'} />)}
        </div>
      ),
    },
    {
      field: "postCode",
      headerName: "Post Code",
      width: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: "details",
      headerName: "Details",
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => (
        <Button
          variant="contained"
          onClick={() => handleClick(params.row.id)}
          style={{
            boxShadow: 'none',
            backgroundColor: '#6c757d'
          }}
        >
          Details
        </Button>
      )
    }
  ];

  useEffect(() => {
    getWorkers({});
  }, []);

  return (
    <>
      <div className="form-content">
        <TextField
          label="Full Name"
          type="text"
          size="small"
          {...register("name")}
          style={{width: "12%", margin: 10}} />
        <TextField
          label="PTS"
          type="text"
          size="small"
          {...register("pts")}
          style={{width: "12%", margin: 10}} />
        <TextField
          label="Phone Number"
          id="outlined-basic"
          variant="outlined"
          type="text"
          {...register("phoneNumber")}
          size="small"
          style={{margin: 10, width: "12%"}} />
        <TextField
          label="Notes"
          id="outlined-basic"
          variant="outlined"
          type="text"
          {...register("notes")}
          size="small"
          style={{margin: 10}} />
        <Button
          variant="contained"
          onClick={handleSubmit(getWorkers)}
          style={{height: 40, margin: 10, boxShadow: 'none', width: "11%"}}>
          Filter
        </Button>
        <Button
          variant="contained"
          onClick={handleCreateWorker}
          style={{height: 40, margin:10, boxShadow: 'none', width: "11%"}}>
          Create New Worker
        </Button>
      </div>
      <Box sx={{height: "800px", width: "75%", margin: '0 auto'}} mt={3}>
        <DataGrid
          rows={workers}
          columns={columns}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: 15
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: '#f0f0f0',
              color: "#000",
              fontWeight: "bold",
            },
            '& .disable-columns': {
              borderRight: '1px solid #bfbfbf'
            },
            '& .border-left': {
              borderLeft: '1px solid #bfbfbf',
            },
          }}
        />
      </Box>
    </>
  );
};

export default StaffDetails;
