/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { addNewProject, editProject, getAllClients, getProjects } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import './Project.css'

const Projects = () => {
    const {state} = useAuth();
    const [projects, setProjects] = useState<any>([]);
    const [clients, setClients] = useState<any>([])

    const handleStatusChange = (id: number) => {
        setProjects((prevRows: any[]) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, status: !row.status } : row
            )
        );
    };

    const getClients = async () => {
        const response = await getAllClients(state.user?.token);
        if(response){
            setClients(response);
        }
    }
    
    const getAllProjects = async () => {
        const response = await getProjects(state.user?.token);
        if(response) {
            setProjects(response);
        }
    }

    const handleAddRow = () => {
        const newRow = {
            id: projects.length + 1,
            projectName: ``,
            company: { companyName: '' },
            companyId: ``,
            postCode: '',
            status: true,
        };
        setProjects((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = projects.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setProjects(updatedRows);
    };

    useEffect(()=> {
        getAllProjects();
        getClients();
    }, [])

    const columns: any = [
        {
            field: "projectName",
            headerName: "Project Name",
            flex: 0.5,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.projectName}
                    fullWidth
                    style={{ height: "5px", margin: 5 }}
                    onChange={(event: any) => {
                        handleChange(params.id, "projectName", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
              ),
        },
        {
            field: "companyId",
            headerName: "Clients",
            flex: 0.5,
            renderCell: (params: any) => (
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={params.row.companyId}
                    size="small"
                    style={{ height: 40, margin: 5, width: '100%' }}
                    onChange={(event: any) => {
                        handleChange(params.id, "companyId", event.target.value);
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                            },
                        },
                    }}
                >
                    <MenuItem disabled value={1}>Project</MenuItem>
                    {clients.map((client: any) =>
                        <MenuItem key={client.id} value={client.id}>
                            {client.companyName}
                        </MenuItem>
                    )}
                </Select>
            ),
        },        
        {
            field: "postCode",
            headerName: "Type of Work",
            flex: 0.2,
            renderCell: (params: any) => (
                <TextField
                    select
                    id="demo-select-small"
                    value={params.row.postCode}
                    size="small"
                    style={{ margin: 5, width: '100%' }}
                    onChange={(event: any) => {
                        handleChange(params.id, "postCode", event.target.value);
                    }}>
                    <MenuItem value={"N/A"}>N/A</MenuItem>
                    <MenuItem value={'Price Work'}>Price Work</MenuItem>
                    <MenuItem value={'Labour'}>Labour</MenuItem>
                    <MenuItem value={'X Other'}>X Other</MenuItem>
                </TextField>
            ),
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 0.2,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.createdDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.15,
            renderCell: (params: any) => (
                <Checkbox
                    checked={params.row.status}
                    style={{color: '#1976d2'}}
                    onChange={() => handleStatusChange(params.row.id)}
                />
            ),
        },
        {
            field: "save",
            headerName: "Save",
            flex: 0.15,
            renderCell: (params: any) => (
                <Button
                    variant="outlined"
                    onClick={() => handleCellEditCommit(params)}
                >
                    Save
                </Button>
            )
        },
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await addNewProject(state.user?.token, params.row.projectName, params.row.companyId, params.row.postCode)
            if(response) {
                toast.success(response.data);
            } else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editProject(state.user?.token, {
                id: params.row.id,
                projectName: params.row.projectName,
                companyId: params.row.companyId,
                status: params.row.status,
                postCode: params.row.postCode
            })
            if(response  === 403) {
                toast.warn('You are not authorized!');
            }else if (response) {
                toast.success(response.data);
                getAllProjects();
            }
        }
    };

    return (
        <div>
            <div className="add-button">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    Add New Project
                </Button>
            </div>
            <Box 
                sx={{
                    width: '80%',
                    margin:'0 auto',
                    height: '840px',
                    "@media (max-width: 800px)": {
                        margin: 1,
                        minWidth: "95%",
                    },
                }}>
                <DataGrid
                    rows={projects}
                    columns={columns}
                    disableRowSelectionOnClick
                    sx={{
                        "@media (max-width: 700px)": {
                            width: "100%",
                            overflowX: "scroll",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15,
                            backgroundColor: '#f0f0f0',
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "@media (max-width: 600px)": {
                            fontSize: "0.7rem",
                        },
                    }}
                />
            </Box>
        </div>
    );
};

export default Projects;