import { Box, Button, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox, ColorPicker } from 'antd';
import React, { useEffect, useState } from "react";
import {
  addClientToResourceGrid,
  deleteClientFromResourceGrid,
  editClientToResourceGrid,
  getClientsFromResourceGrid
} from "../../../../../api/apiCall";
import { useAuth } from "../../../../../auth/auth";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const Clients = ({connection, isConnected, hide, setHide}: any) => {
  const {state} = useAuth();
  const [searchParams] = useSearchParams();
  const [clients, setClients] = React.useState<any>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [biggestOrderNumber, setBiggestOrderNumber] = React.useState<any>();
  const [loading, setLoading] = useState(false)

  const colors = [
    '#F4D6EE', '#D5F4EE', '#DCF5D7', '#D5DDF0',
    '#c1c1f7', '#F3D4DA', '#FFDBBB'
  ]

  const handleColorChange = (color: any, id: any) => {
    const hexColor = color.toHexString();
    const updatedClients = clients.map((client: any) => 
      client.id === id ? { ...client, color: hexColor } : client
    );
    setClients(updatedClients);
  };

  const handleSelectChange = (event: any, id: any) => {
    const selectedColor = event.target.value;
    const updatedClients = clients.map((client: any) =>
      client.id === id ? { ...client, color: selectedColor } : client
    );
    setClients(updatedClients);
  };

  const handleChange = (id: any, field: any, newValue: any) => {
    const updatedRows = clients.map((row: any) =>
      row.id === id ? { ...row, [field]: newValue } : row
    );
    setClients(updatedRows);
  };

  const handleAddRow =async () => {
    const newRow = {
      id: clients.length + 1,
      clientName: ``,
      endRow: 0,
      startRow: 0,
      color: '#fff',
      codeName: `${searchParams.get('week')}-${searchParams.get('year')}`
    };
    setClients((prevRows: any) => [...prevRows, newRow]);
    const response = await addClientToResourceGrid(state.user?.token, {
      codeName: `${searchParams.get('week')}-${searchParams.get('year')}`,
      clientName: newRow.clientName,
      startRow: newRow.startRow,
      endRow: newRow.endRow,
      color:  newRow.color,
      orderNumber: biggestOrderNumber + 1
    })

    if(response) {
      setLoading(true)
      toast.success('Added new client successfully')
      if(connection) {
        await connection.invoke('UpdateClientsFromGrid', `${searchParams.get('week')}-${searchParams.get('year')}`)
      }
    }
    setLoading(false)
  };

  const handleSaveChanges = async () => {
    const response = await editClientToResourceGrid(state.user?.token, clients);
    if(response) {
      toast.success('Updated Clients successfully')
    }
    if(connection) {
      await connection.invoke('UpdateClientsFromGrid', `${searchParams.get('week')}-${searchParams.get('year')}`)
    }
  }

  const getClients = async () => {
    const response = await getClientsFromResourceGrid(state.user?.token, `${searchParams.get('week')}-${searchParams.get('year')}`);
    if(response) {
      setClients(response)
      const orderNumbers: [] = response.map((client: any) => client.orderNumber);
      const maxOrderNumber =
        orderNumbers && orderNumbers.length > 0 ? Math.max(...orderNumbers) : 0;
      setBiggestOrderNumber(maxOrderNumber);
    }
  }

  const columns: any = [
    {
      field: "orderNumber",
      headerName: "#",
      width: 30,
      filterable: false,
    },
    {
      field: "clientName",
      headerName: "CLIENTS",
      width: 250,
      renderCell: (params: any) => (
        <TextField
          size="small"
          value={params.row.clientName}
          style={{margin: 5}}
          fullWidth
          onChange={(event: any) => {
              handleChange(params.id, "clientName", event.target.value);
          }}
          inputProps={{
              ...params.inputProps,
              onKeyDown: (event) => {
                if (event.key === ' ') {
                  event.stopPropagation();
                }
              },
          }}
        />
    ),
    },
    {
      field: "startRow",
      headerName: "ROW START",
      width: 120,
      renderCell: (params: any) => (
        <TextField
          type="number"
          size="small"
          value={params.row.startRow}
          style={{ width: "90px", height: "5px", margin: 5 }}
          onChange={(event: any) => {
            handleChange(params.id, "startRow", event.target.value);
          }}
        />
      ),
    },
    {
      field: "endRow",
      headerName: "ROW END",
      width: 120,
      align: 'center',
      renderCell: (params: any) => (
        <TextField
          type="number"
          size="small"
          value={params.row.endRow}
          style={{ width: "90px", height: "5px", margin: 5 }}
          onChange={(event: any) => {
            handleChange(params.id, "endRow", event.target.value);
          }}
        />
      ),
    },
    {
      field: "color",
      headerName: "COLOR",
      width: 200,
      renderCell: (params: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            style={{ 
              height: "28px", 
              width: "50px",
              backgroundColor: params.row.color
            }}
            defaultValue={params.row.color}
            onChange={(event) => handleSelectChange(event, params.row.id)}
          >
            {colors.map((options: any) => (
              <MenuItem
                value={options}
                style={{ height: 40, backgroundColor: options }}
              />
            ))}
          </Select>
          <ColorPicker
            style={{ fontSize: 20, margin: 10 }}
            showText
            value={params.row.color}
            onChange={(color: any) => handleColorChange(color, params.row.id)}
          />
        </div>
      )
    }
  ];

  const handleRowSelection = (newSelectionModel: any) => {
    setSelectedRows(newSelectionModel);
  };

  const deleteWorker = async () => {
    const response = await deleteClientFromResourceGrid(state.user?.token, selectedRows);
    if(response) {
      toast.error('Deleted client successfully')
      if(connection) {
        await connection.invoke('UpdateClientsFromGrid', `${searchParams.get('week')}-${searchParams.get('year')}`)
      }
    }
  };

  useEffect(() => {
    getClients();
  }, [])

  useEffect(() => {
    getClients();
  }, [isConnected])

  useEffect(() => {
    if(connection) {
      connection.on('UpdateClientsFromGrid', () => {
        getClients();
      })
      return () => {
        connection.off('UpdateClientsFromGrid');
      }
    }
  }, [connection]);

  return (
    <div>
      <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', margin: 10 }}>Clients</div>
      <Box sx={{ height: 400, width: "80%", margin: '0 auto' }}>
        <DataGrid
          rows={clients}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleRowSelection}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "",
              color: "",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </Box>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{margin: 10}}>
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            style={{
              margin: "5px",
              color: "white",
              borderColor: "black",
              height: "35px",
              boxShadow: "none",
            }}
          >Save Changes</Button>
          <Button
            variant="contained"
            onClick={handleAddRow}
            disabled={loading}
            style={{
              margin: "5px",
              color: "white",
              borderColor: "black",
              height: "35px",
              boxShadow: "none",
            }}
          >{loading? <CircularProgress size={25} style={{color: 'white'}} /> : 'Add a Client'}</Button>
          &nbsp;&nbsp;Freez Clients Panel: &nbsp;
          <Checkbox
            checked={hide}
            style={{color: hide && '#74c365',transform: 'scale(1.7)'}}
            onChange={(checked: any) => setHide(!hide)}
          />
        </div>
        {selectedRows && selectedRows.length > 0 && (
          <Button
            onClick={deleteWorker}
            style={{
              margin: "20px",
              color: "white",
              backgroundColor: "red",
              height: "35px",
              boxShadow: "none",
              alignItems: 'right'
            }}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  )
}

export default Clients;
