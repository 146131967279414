import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useAuth } from "../../auth/auth";
import { fatigueCheckV2 } from "../../api/apiCall";
import { useSearchParams } from "react-router-dom";
import Header from "../Resource/components/Header/Header";

const FatigueCheck = () => {
    const {state} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [workers, setWorkers] = useState([]);
    const [date, setDate] = useState(null);
    const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
    const [year, setYear] = useState<any>(searchParams.get('year') || 2024);

    const getWorkers = async () => {
        const response = await fatigueCheckV2(state.user?.token, year, week);
        setWorkers(response)
    };

    const columns: any = [
        {
            field: "worker",
            headerName: "Full Name",
            flex: 0.9,
            cellClassName: (params: any) => "style-name"
        },
        { 
            field: "saturday", headerName: "Saturday", flex: 0.5, filterable: false, headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
        {
            field: "sunday", headerName: "Sunday", flex: 0.5, filterable: false, headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
        {
            field: "monday", headerName: "Monday", flex: 0.5, filterable: false, headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
              </div>
            ),
        },
        {
            field: "tuesday", headerName: "Tuesday", flex: 0.5, filterable: false, headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
        {
            field: "wednesday", headerName: "Wednesday", flex: 0.5, filterable: false, headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
        {
            field: "thursday", headerName: "Thursday", flex: 0.5, filterable: false,headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
        {
            field: "friday", headerName: "Friday", flex: 0.5, filterable: false,
            headerAlign: 'center',
            renderCell: (params: any) => (
                <div
                    style={{
                        backgroundColor: params.value?.isWorking ? (params.value?.isFatigued ? "red" : "#55bd55") : 'transparent',
                        display: "flex",
                        color: 'white',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        marginTop: 10,
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        borderRadius: 5,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                >
                    {params.value?.isWorking ? params.value?.totalHours : ''}
                </div>
            ),
        },
    ];

    const formattedRows: any = workers.map((row: any, index) => {
        const maxDayHours = Math.max(
            ...row.fatigueCheckResults.map((day: { totalHours: string }) => parseFloat(day.totalHours))
        );
    
        return {
            id: index,
            worker: `${row.fullName}`,
            monday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Monday"),
            tuesday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Tuesday"),
            wednesday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Wednesday"),
            thursday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Thursday"),
            friday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Friday"),
            saturday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Saturday"),
            sunday: row.fatigueCheckResults.find((day: { dayOfWeek: string }) => day.dayOfWeek === "Sunday"),
            totalHours: maxDayHours
        };
    });

    formattedRows.sort((a: any, b: any) => b.totalHours - a.totalHours);

    useEffect(() => {
        setSearchParams({ week, year });
        getWorkers();
    }, [date]);

    return (
        <>
            <Header
                setDate={setDate}
                week={week}
                setWeek={setWeek}
                year={year}
                setYear={setYear}
            />
            <Box
                sx={{
                    margin: '0 auto',
                    mt: 13,
                    height: 800,
                    width: "90%",
                    "& .MuiDataGrid-columnHeader": {
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 15,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    ".style-name":{
                        fontSize: 14,
                        backgroundColor: "#f5f5f5",
                        fontWeight: 'bold'
                    }
                }}>
                <DataGrid rows={formattedRows} columns={columns} />
            </Box>
        </>
    );
}

export default FatigueCheck;
