import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, ListItem, MenuItem, TextField } from "@mui/material";
import { Modal } from "antd";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { assignTicketToUser, getTickets, removeTicketFromUser } from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import EditTicket from "./EditTicket/EditTicket";
import { useNavigate } from "react-router-dom";

const Ticket = ({profileInfo, getWorkerInfo}: any) => {
    const {state} = useAuth();
    const navigate = useNavigate();
    const [isModalTicketOpen, setIsModalTicketOpen] = useState(false);
    const [isModalEditTicketOpen, setIsModalEditTicketOpen] = useState(false);
    const [tickets, setTickets] = useState<any>([]);
    const [selectedTicket, setSelectedTicket] = useState<any>();
    const [image, setImage] = useState<any>(null);
    const [expDate, setExpDate] = useState<any>(dayjs());
    const [ticketStatus, setTicketStatus] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [id, setId] = useState<any>('');
    const [, setOpen] = useState(false);

    useEffect(() => {
        getTickets(state.user?.token).then(setTickets);
    }, []);

    const handleCancelTicket = () => {
        setIsModalTicketOpen(false);
    };

    const handleCancelEditTicketModal = () => {
        setSelectedTicket([])
        setIsModalEditTicketOpen(false);
    };

    const handleImageUpload = (event: any) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileArray = Array.from(files);
            setImage(fileArray);
        }
    };

    const addTicket = async () => {
        const response = await assignTicketToUser(state.user?.token, {
            userId: profileInfo.id,
            ticketId: selectedTicket.id,
            expDate: format(expDate.$d, 'yyyy-MM-dd'),
            ticketStatus: ticketStatus,
            ticketDocuments: image
        })
        if(response) {
            setIsModalTicketOpen(false);
            toast.success('Ticket added successfully');
            getWorkerInfo();
        }
    };

    const getTicketStatus = (ticketStatus: any) => {
        switch(ticketStatus) {
            case 0: 
                return 'Pending'
            case 1:
                return 'Approved'
            case 2:
                return 'Denied'
            case 3:
                return 'Not Verified'
        }
    }

    const handleDelete = async (id: any) => {
        const response = await removeTicketFromUser(state.user?.token, id);
        if(response) {
            getWorkerInfo();
        } else {
            toast.error('Something went wrong!')
        }
    };

    const ticketsColumns: any = [
        {
            field: "skillName",
            headerName: "Tickets",
            flex: 3,
            renderCell: (params: any) => (
                <div>{params.row.ticket.ticketName}</div>
            ),
        },
        {
            field: "expDate",
            headerName: "Expiration Date",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            cellClassName: (params: any) => {
                const today = new Date();
                const expirationDate = new Date(params.value);
                return expirationDate <= today ? "expired" : "approved";
            },
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.expDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "ticketStatus",
            headerName: "Status",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => (
                <div>
                    {getTicketStatus(params.row.ticketStatus)}
                </div>
            ),
        },
        {
            field: "images",
            headerName: "Images",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => 
                <Button
                    variant="outlined"
                    onClick={() => {
                        navigate("/pace-team/resourcing/get-worker-tickes-documents", {
                            state: { userTicketId: params.row.id, name: profileInfo?.name, surname: profileInfo?.surname, ticketName: params.row.ticket.ticketName
                        }});
                    }}>
                        Documents
                </Button>
        },
        {
            field: "edit",
            headerName: "Edit Ticket",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => 
                <Button
                    variant="contained"
                    style={{backgroundColor: 'gray', boxShadow: 'none'}}
                    onClick={() => {
                        setId(params.id);
                        setSelectedTicket(params.row.ticket)
                        setExpDate(dayjs(params.row.expDate))
                        setTicketStatus(params.row.ticketStatus)
                        setIsModalEditTicketOpen(true)
                    }}
                    >
                        Edit
                </Button>
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: `Are you sure you want to delete ${params.row.ticket.ticketName}?`,
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const filteredTickets = profileInfo?.tickets
    ? profileInfo.tickets.filter((ticket: any) =>
        ticket.ticket.ticketName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

    return (
        <>
            <Box display="flex" justifyContent="right" alignItems="center" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setIsModalTicketOpen(true)}}
                    style={{ width: 150, boxShadow: "none" }}
                >
                    Add Ticket
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <h2>Tickets</h2>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    style={{width: 300}}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <Box>
                <DataGrid
                    rows={filteredTickets}
                    columns={ticketsColumns}
                    style={{height: 474}}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15.5,
                        }
                    }}
                />
            </Box>
            <Modal
                title={`Add Ticket for ${profileInfo?.name} ${profileInfo?.surname}`}
                onCancel={handleCancelTicket}
                open={isModalTicketOpen}
                footer={[
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button variant="contained" sx={{ boxShadow: 'none', mb: 2 }} onClick={addTicket}>
                            Add Ticket
                        </Button>
                    </div>
                ]}>
                <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr" }} gap={4} mt={4}>
                    <Autocomplete
                        size="small"
                        options={tickets}
                        onChange={(_, newValue) => {
                            setSelectedTicket(newValue)
                        }}
                        getOptionLabel={(option: any) => option.ticketName}
                        renderOption={(props, option) => (
                            <ListItem {...props} key={option.ticketName}>
                              {option.ticketName}
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Ticket" placeholder="Select Ticket" />
                        )}
                    />
                    <TextField
                        type="file"
                        variant="outlined"
                        fullWidth
                        onChange={handleImageUpload}
                        size="small"
                        inputProps={{
                            multiple: true,
                            accept: ".jpg,.jpeg,.png,.gif,.bmp,.tiff,.xls,.xlsx,.doc,.docx,.pdf"
                        }}
                        sx={{
                            '& input[type="file"]::file-selector-button': {
                                backgroundColor: '#e3e3e3',
                                color: '#000',
                                border: 'none',
                                padding: '4px 12px',
                                borderRadius: '6px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            },
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                slotProps={{textField: { size: 'small', fullWidth: true }}}
                                label="Experation Date"
                                value={expDate}
                                onChange={(value) => {setExpDate(value)}}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                     <TextField
                        select
                        value={ticketStatus}
                        id="demo-select-small"
                        size="small"
                        label="Status"
                        sx={{mb: 4}}
                        onChange={(e: any) => {setTicketStatus(e.target.value)}}
                    >
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Approved</MenuItem>
                        <MenuItem value={2}>Denied</MenuItem>
                        <MenuItem value={3}>Not Verified</MenuItem>
                    </TextField>
                </Box>
            </Modal>
            <Modal
                title={`Edit Ticket for ${profileInfo?.name} ${profileInfo?.surname}`}
                onCancel={handleCancelEditTicketModal}
                open={isModalEditTicketOpen}
                footer={[
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {/* <Button variant="contained" sx={{ boxShadow: 'none', mb: 2 }} onClick={addTicket}>
                            Edit Ticket
                        </Button> */}
                    </div>
                ]}>
                    <EditTicket
                        id={id}
                        profileInfo={profileInfo}
                        tickets={tickets}
                        selectedTicket={selectedTicket}
                        setSelectedTicket={setSelectedTicket}
                        expDate={expDate}
                        setExpDate={setExpDate}
                        ticketStatus={ticketStatus}
                        setTicketStatus={setTicketStatus}
                        setIsModalEditTicketOpen={setIsModalEditTicketOpen}
                        getWorkerInfo={getWorkerInfo}
                    />
                </Modal>
        </>
    )
}

export default Ticket;