import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Modal } from "antd";
import { deleteJobPermanently, getDeletedJobsHistory, restoreDeletedJob } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { toast } from "react-toastify";

const RestoreShifts = () => {
    const {state} = useAuth();
    const [, setOpen] = useState(false);
    const [shifts, setShifts] = useState<any>([]);

    const getDeletedShifts = async ()  => {
        const response = await getDeletedJobsHistory(state.user?.token);
        setShifts(response)
    }

    const handleDeleteJobPermanently = async (deleteJobId: any) => {
        const response = await deleteJobPermanently(state.user?.token, deleteJobId);
        if(response) {
            getDeletedShifts();
        }else {
            toast.info('Not authorized!')
        }
    }

    const handlerestoreDeletedJob = async (deleteJobId: any) => {
        const response = await restoreDeletedJob(state.user?.token, deleteJobId);
        if(response) {
            getDeletedShifts();
        }
    }

    useEffect(() => {
        getDeletedShifts()
    }, []);

    const columns: any = [
        {
          field: "whatsAppName",
          headerName: "Team Name",
          flex: 0.5,
        },
        {
          field: "projectName",
          headerName: "Project Name - Client",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
          renderCell: (params: any) => {
            return <div>{params.row.project.projectName}</div>
          }
        },
        {
          field: "JobDate",
          headerName: "Date",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
          renderCell: (params: any) => {
            return <div>{format(new Date(params.row.jobDate || Date.now()), 'EEEE')} - {format(new Date(params.row.jobDate || Date.now()), 'dd/MM/yyyy')}</div>
          }
        },
        {
          field: "location",
          headerName: "Location",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5
        },
        {
          field: "DeletedDate",
          headerName: "Deleted Date - UK",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
          renderCell: (params: any) => {
            return <div>{format(new Date(params.row.deletedDate || Date.now()), 'dd/MM/yyyy hh:mm')}</div>
          }
        },
        {
          field: "deletedBy",
          headerName: "Deleted By",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
        },
        {
          field: "restore",
          headerName: "Restore",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
          renderCell: (params: any) => (
            <Button
              variant="contained"
              onClick={() => handlerestoreDeletedJob(params.row.id)}
              style={{boxShadow: 'none', backgroundColor: 'green'}}>
              Restore
            </Button>
          )
        },
        {
          field: "delete",
          headerName: "Delete",
          align: 'center',
          headerAlign: 'center',
          flex: 0.5,
          renderCell: (params: any) => (
            <Button
              variant="contained"
              color="error"
              style={{boxShadow: 'none'}}
              onClick={() => {
                setOpen(true);
                Modal.confirm({
                  title: `Are you sure you want to remove ${params.row.whatsAppName}? `,
                  onOk: () => handleDeleteJobPermanently(params.row.id),
                  okButtonProps: { danger: true },
                  okText: 'YES',
                  cancelText: 'NO',
                  width: 470,
                  centered: true
                });
              }}>
                Delete
            </Button>
          )
        }
    ];

    return (
        <div>
            <Box
                sx={{
                    width: "95%",
                    height: '840px',
                    margin: "0 auto",
                    mt: 4,
                    "@media (max-width: 800px)": {
                        overflowX: "auto",
                        margin: 1,
                        minWidth: "95%",
                    },
                }}
            >
                <DataGrid
                    columns={columns}
                    rows={shifts}
                    disableRowSelectionOnClick
                    sx={{
                        "@media (max-width: 700px)": {
                            minWidth: "100%",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15,
                            backgroundColor: '#f0f0f0',
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#f1f1f1",
                        },
                    }}
                />
            </Box>
        </div>
    )
}

export default RestoreShifts;