import { Box, Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getUpdateWorkerModel, updateWorker } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { toast } from "react-toastify";

type FormValues = {
    id: string
    name: string;
    surname: string;
    pts: string;
    username: string;
    phoneNumber: string;
    notes: string;
    email: string;
    suffix: string;
    postCode: string;
    location: string;
    driver: any;
    verified: any;
}

const EditWorker = () => {
    const {state} = useAuth();
    const location = useLocation();
    const { workerId } = location.state || {};
    const [workerData, setworkerData] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const {register, handleSubmit,control, reset} = useForm<FormValues>();

    const getUpdateWorkerData = async () => {
        setIsLoading(true);
        const response = await getUpdateWorkerModel(state.user?.token, workerId);
        setworkerData(response);
        setIsLoading(false);
        reset(response);
    }

    const handleUpdateWorker = async (data: any) => {
        setButtonLoading(true);
        const response = await updateWorker(state.user?.token, data);
        if(response?.status === 200) {
            setTimeout(() => {
                setButtonLoading(false);
                toast.success('Updated successfully');
            }, 500);
        }else{
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        getUpdateWorkerData();
    }, [])
    
    return (
        <Box sx={{ margin: "auto", backgroundColor: "#f2f5fc", padding: 5 }}>
            <Box sx={{ maxWidth: 650, margin: "auto", backgroundColor: "#fff", padding: 5 }}>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Edit Worker Account
                </Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }} />

                {isLoading ? (
                    <Box display="flex" justifyContent="center" mt={5}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box display="grid" gridTemplateColumns={{ xs: "1fr" }} gap={3} mt={7}>
                            <TextField label="Name" variant="outlined" fullWidth size="small" {...register("name")} />
                            <TextField label="Surname" variant="outlined" fullWidth size="small" {...register("surname")} />
                            <TextField label="Username" variant="outlined" fullWidth size="small" {...register("username")} disabled />
                            <TextField label="PTS" variant="outlined" fullWidth size="small" {...register("pts")} />
                            <TextField label="Phone Number" variant="outlined" fullWidth size="small" {...register("phoneNumber")} />
                            <TextField label="Email" variant="outlined" fullWidth size="small" {...register("email")} />
                            <TextField label="Post Code" variant="outlined" fullWidth size="small" {...register("postCode")} />
                            <TextField label="Location" variant="outlined" fullWidth size="small" {...register("location")} />
                            <TextField label="Suffix" variant="outlined" fullWidth size="small" {...register("suffix")} />
                            <TextField label="Notes" variant="outlined" fullWidth size="small" multiline rows={6} {...register("notes")} />
                        </Box>

                        <Typography style={{ fontSize: 17 }}>
                            Driver:
                            <Controller
                                name="driver"
                                control={control}
                                render={({ field }) => <Checkbox {...field} checked={field.value} />}
                            />
                            Verified:
                            <Controller
                                name="verified"
                                control={control}
                                render={({ field }) => <Checkbox {...field} checked={field.value} />}
                            />
                        </Typography>

                        <Box display="flex" justifyContent="center" mt={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{boxShadow: "none", backgroundColor: "#156bc2", width: 160}}
                                disabled={buttonLoading}
                                onClick={handleSubmit(handleUpdateWorker)}
                            >
                                {buttonLoading ? (
                                    <CircularProgress size={25} style={{color: 'white'}} />
                                ) : ('Edit Worker')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );

};

export default EditWorker;