import { Box, Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { addWorkerAccount, getAllCompetenecies, getAllExperiences, getTickets } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type FormValues = {
    name: string;
    surname: string;
    pts: string;
    phoneNumber: string;
    email: string;
    postCode: string;
    location: string;
    suffix: string;
    password: string;
    notes: string;
    driver: boolean;
    competencies: any[];
    experiences: string[];
    tickets: string[];
}

const CreateWorker = () => {
    const {state} = useAuth();
    const [competencies, setComptenecies] = useState<any>([]);
    const [experiences, setExperiences] = useState<any>([]);
    const [tickets, setTickets] = useState<any>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedCompetencies, setSelectedCompetencies] = useState<string[]>([]);
    const [selectedExperiences, setSelecetedExperiences] = useState<string[]>([]);
    const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
    const {register, handleSubmit, formState: {errors}, setValue, reset} = useForm<FormValues>()

    const createWorker = async (data: any) => {
        setButtonLoading(true);
        data.competencies = selectedCompetencies;
        data.experiences = selectedExperiences;
        data.tickets = selectedTickets;
        const response = await addWorkerAccount(state.user?.token, data);
        if(response) {
            setTimeout(() => {
                setButtonLoading(false);
                toast.success('Worker added successfully');
                reset({
                    name: '',
                    surname: '',
                    pts: 'N/A',
                    phoneNumber: '',
                    email: 'N/A',
                    postCode: 'N/A',
                    location: 'N/A',
                    suffix: 'N/A',
                    password: 'PaceWorker',
                    notes: '',
                    driver: false,
                    competencies: [],
                    experiences: [],
                    tickets: []
                });
            }, 500);
        }
    };

    useEffect(() => {
        getAllCompetenecies(state.user?.token).then(setComptenecies);
        getAllExperiences(state.user?.token).then(setExperiences);
        getTickets(state.user?.token).then(setTickets);
    }, []);

    return (
        <Box sx={{backgroundColor: "#f2f5fc"}}>
        <Box
            sx={{
                maxWidth: 1000,
                height: 800,
                margin: "auto",
                padding: 5,
                backgroundColor: "#fff"
            }}
        >
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Create New Worker
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }} />
            <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }} gap={3} mt={7} >
            <TextField
                label="Name"
                placeholder="Write name"
                variant="outlined"
                size="small"
                fullWidth
                {...register("name", {
                    required: "Name is required"
                })}
                onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setValue("name", trimmedValue);
                }}
                error={!!errors.name}
            />

                <Autocomplete
                    multiple
                    size="small"
                    {...register("competencies")}
                    options={competencies}
                    disableCloseOnSelect
                    onChange={(_, newValue) => {
                        const competenciesSelected = newValue.map(item => item.id);
                        setSelectedCompetencies(competenciesSelected)
                    }}
                    getOptionLabel={(option: any) => option.skillName}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps }: any = props;
                        return (
                            <li key={key} {...optionProps}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                    {option.skillName}
                            </li>
                        );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Competencies" placeholder="Select Competencies" />
                    )}
                />
                <TextField
                    label="Surname"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.surname}
                    {...register("surname", {
                        required: "Surname is required"
                    })}
                    onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setValue("surname", trimmedValue);
                    }}
                />
                <Autocomplete
                    multiple
                    size="small"
                    options={experiences}
                    disableCloseOnSelect
                    {...register("experiences")}
                    onChange={(_, newValue) => {
                        const experiencesSelected = newValue.map(item => item.id);
                        setSelecetedExperiences(experiencesSelected)
                    }}
                    getOptionLabel={(option: any) => option.experienceName}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps }: any = props;
                        return (
                            <li key={key} {...optionProps}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                    {option.experienceName}
                            </li>
                        );
                    }}
                    style={{width: 500}}
                    renderInput={(params) => (
                        <TextField {...params} label="Experiences" placeholder="Select Experiences" />
                    )}
                />
                <TextField
                    label="PTS"
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={"N/A"}
                    {...register("pts")}
                    onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setValue("pts", trimmedValue);
                    }}
                />
                <Autocomplete
                    multiple
                    size="small"
                    {...register("tickets")}
                    options={tickets}
                    disableCloseOnSelect
                    onChange={(_, newValue) => {
                        const ticketsSelected = newValue.map(item => item.id);
                        setSelectedTickets(ticketsSelected)
                    }}
                    getOptionLabel={(option: any) => option.ticketName}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps }: any = props;
                        return (
                            <li key={key} {...optionProps}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                    {option.ticketName}
                            </li>
                        );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Tickets" placeholder="Select Tickets" />
                    )}
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!errors.phoneNumber}
                    {...register("phoneNumber", {
                        required: "Phone Number is required"
                    })}
                    onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setValue("phoneNumber", trimmedValue);
                    }}
                />
                
                <TextField
                    label="Suffix"
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={"N/A"}
                    {...register("suffix")}
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={"N/A"}
                    {...register("email")}
                />
                <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...register("location")}
                    defaultValue={"N/A"}
                />
                <TextField
                    label="Post Code"
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={"N/A"}
                    {...register("postCode")}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={"PaceWorker"}
                    {...register("password")}
                />
            </Box>
            <TextField
                sx={{mt: 3}}
                label="Notes"
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={4}
                {...register("notes")}
            />
            <Typography style={{fontSize: 17}}>Driver:<Checkbox {...register("driver")} /></Typography>
            <Box display="flex" justifyContent="center" mt={3}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{boxShadow: "none", backgroundColor: "#156bc2", width: 160}}
                    onClick={handleSubmit(createWorker)}
                >
                    {buttonLoading ? (
                        <CircularProgress size={25} style={{color: 'white'}} />
                    ) : ('Create Worker')}
                </Button>
            </Box>
        </Box>
        </Box>
    )
};

export default CreateWorker;