import { Autocomplete, Box, Button, ListItem, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Modal } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { assignExperienceToUser, getAllExperiences, removeExperienceFromUser } from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import { toast } from "react-toastify";

const Experiences = ({profileInfo, getWorkerInfo}: any) => {
    const {state} = useAuth();
    const [experiences, setExperiences] = useState<any>([]);
    const [selectedExperiences, setSelecetedExperiences] = useState<string[]>([]);
    const [isModalExperienceOpen, setIsModalExperienceOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [, setOpen] = useState(false);

    const handleCancelExperience = () => {
        setIsModalExperienceOpen(false);
    };

    const addExperience = async () => {
        const response = await assignExperienceToUser(state.user?.token, profileInfo.id, selectedExperiences);
        if(response) {
            if(response) {
                setIsModalExperienceOpen(false);
                toast.success('Experience added successfully');
                getWorkerInfo();
            }
        }
    };

    useEffect(() => {
        getAllExperiences(state.user?.token).then(setExperiences);
    }, []);

    const handleDelete = async (id: any) => {
        const response = await removeExperienceFromUser(state.user?.token, id);
        if(response) {
            getWorkerInfo();
        } else {
            toast.error('Something went wrong!')
        }
    }

    const expereincesColumns: any = [
        {
            field: "experienceType",
            headerName: "Experience",
            flex: 2,
            renderCell: (params: any) => (
                <div>{params.row.experienceType.experienceName}</div>
            ),
        },
        {
            field: "addedDate",
            headerName: "Added Date",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.addedDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: `Are you sure you want to delete ${params.row.experienceType.experienceName}?`,
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const filteredExperiences = profileInfo?.experiences
    ? profileInfo.experiences.filter((exp: any) =>
        exp.experienceType.experienceName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];
    
    return (
         <Box
            sx={{
                margin: "auto",
                padding: 4,
                background: "#fff",
                marginTop: 5,
                border: '1px solid rgb(236, 236, 236)'
            }}>
            <Box display="flex" justifyContent="right" alignItems="center" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsModalExperienceOpen(true)}
                    sx={{width: 170, boxShadow: "none"}}
                >
                    Add Experience
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <h2>Experiences</h2>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    style={{width: 300}}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <Box>
                <DataGrid
                    rows={filteredExperiences}
                    columns={expereincesColumns}
                    style={{height: 474 }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15.5,
                        }
                    }}
                />
            </Box>
            <Modal
                title={`Add Experience for ${profileInfo?.name} ${profileInfo?.surname}`}
                onCancel={handleCancelExperience}
                open={isModalExperienceOpen}
                footer={[
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Button
                            variant="contained"
                            sx={{boxShadow: 'none', mb: 2}}
                            onClick={addExperience}
                        >
                            Add Experience
                        </Button>
                    </div>
                ]}>
                <Box display="grid" gridTemplateColumns={{xs: "1fr", md: "1fr"}} gap={4} mt={4}>
                    <Autocomplete
                        size="small"
                        options={experiences}
                        onChange={(_, newValue) => {
                            setSelecetedExperiences(newValue.id)
                        }}
                        getOptionLabel={(option: any) => option.experienceName}
                        renderOption={(props, option) => (
                            <ListItem {...props} key={option.experienceName}>
                              {option.experienceName}
                            </ListItem>
                          )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Experience"
                                placeholder="Select Experience"
                            />
                        )}
                    />
                </Box>
            </Modal>
            </Box>
    )
}

export default Experiences;
