import React, { useEffect, useState } from "react";
import { getWorkersFromJob } from "../../../../../api/apiCall";
import { useAuth } from "../../../../../auth/auth";
import "./WorkersTable.css";
import { toast } from "react-toastify";

const WorkersTable = ({ id }: any) => {
    const { state } = useAuth();
    const [workers, setWorkers] = useState<any>([]);

    const getWorkers = async () => {
        try {
            const response = await getWorkersFromJob(state.user?.token, id);
            if (response && Array.isArray(response)) {
                setWorkers(response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getWorkers();
    }, []);

    const copyColumnToClipboard = (columnName: string) => {
        const columnData = workers.map((worker: any) => worker[columnName]).join("\n");
    
        navigator.clipboard.writeText(columnData)
            .then(() => toast.info(`Copied to clipboard`))
            .catch(err => console.error("Failed to copy:", err));
    };

    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => copyColumnToClipboard('workRole')}>ROLE</th>
                        <th onClick={() => copyColumnToClipboard('fullName')}>NAME</th>
                        <th onClick={() => copyColumnToClipboard('pts')}>PTS</th>
                        <th onClick={() => copyColumnToClipboard('phoneNumber')}>PHONE NUMBER</th>
                        <th onClick={() => copyColumnToClipboard('postCode')}>POST CODE</th>
                    </tr>
                </thead>
                <tbody>
                    {workers.map((worker: any, index: number) => (
                        <tr key={index}>
                            <td>{worker.workRole}</td>
                            <td>{worker.fullName}</td>
                            <td>{worker.pts}</td>
                            <td>{worker.phoneNumber}</td>
                            <td>{worker.postCode}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WorkersTable;
