/* eslint-disable react-hooks/exhaustive-deps */
import './Header.css';
import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Tooltip,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
} from "@mui/material";
import { Link, useLocation  } from 'react-router-dom';
import { useAuth } from '../../../../auth/auth';
import { removeUser } from '../../../../auth/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { generatePayrollReport } from '../../../../api/apiCall';

type Props = {
    setDate: React.Dispatch<React.SetStateAction<any>>
    week: number
    setWeek: React.Dispatch<React.SetStateAction<number>>
    year: number
    setYear: React.Dispatch<React.SetStateAction<number>>
};

const Header = ({ setDate, week, setWeek, year, setYear }: Props) => {
    const {state, dispatch} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElStaff, setAnchorElStaff] = React.useState(null);
    const [anchorElRTdata, setAnchorElRTData] = React.useState(null);
    const [anchorElSentinel, setAnchorElSentinel] = React.useState(null);
    const [anchorElonCall, setAnchorElonCall] = React.useState(null);
    const [anchorElMore, setAnchorElMore] = React.useState(null);
    const [avatarColor, setAvatarColor] = useState<any>();
    const open = Boolean(anchorEl);
    const openStaff = Boolean(anchorElStaff);
    const openRtData = Boolean(anchorElRTdata);
    const openSentinel = Boolean(anchorElSentinel);
    const openOnCall = Boolean(anchorElonCall);
    const openMore = Boolean(anchorElMore);
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:1245px)');

    const colors = [
        '#444c5c', '#ce5a57', '#78a5a3', '#e1b16a', '#aaaaaa',
        '#efc070', '#A2C4C9', '#8E7CC3', '#D5A6BD', '#EA9999'
    ];

    const logOut = () => {
        dispatch(removeUser());
        localStorage.removeItem('avatarColor');
    }

    const getRailwayYearStartDate = (year: number): Date => {
        const aprilFirst = new Date(year, 3, 1);
        const dayOfWeek = aprilFirst.getDay();
        const daysBeforeSaturday = (dayOfWeek + 1) % 7;
        return new Date(year, 2, 31 - daysBeforeSaturday);
    };

    const getWeekStartDate = (week: number, year: number): Date => {
        const railwayYearStartDate = getRailwayYearStartDate(year);
        const startDate = new Date(railwayYearStartDate);
        startDate.setDate(railwayYearStartDate.getDate() + week * 7);
        setDate(startDate);
        return startDate;
    };

    const handleSubmit = () => {
        if (week && year) {
            if (week < 1 || week > 52 || year < 2024 || year > 2026) {
                alert('Invalid week or year');
                return;
            }
            getWeekStartDate(week, year);
        } else {
            alert('Please fill in both fields');
        }
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickStaff = (event: any) => {
        setAnchorElStaff(event.currentTarget);
    };

    const handleClickRTData = (event: any) => {
        setAnchorElRTData(event.currentTarget);
    };

    const handleClickSentinel = (event: any) => {
        setAnchorElSentinel(event.currentTarget);
    };

    const handleClickOnCall = (event: any) => {
        setAnchorElonCall(event.currentTarget);
    };

    const handleClickMore = (event: any) => {
        setAnchorElMore(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseStaff = () => {
        setAnchorElStaff(null);
    };

    const handleCloseRtData = () => {
        setAnchorElRTData(null);
    };

    const handleCloseSentinel = () => {
        setAnchorElSentinel(null);
    };

    const handleCloseOnCall = () => {
        setAnchorElonCall(null);
    };

    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    const handleGeneratePayrollReport = async () => {
        setAnchorElMore(null);
        const response = await generatePayrollReport(
            state.user?.token,
            week,
            year
        );
    };

    const getInitials = () => {
        return `${state.user?.name?.[0]}${state.user?.surname?.[0]}`;
    };

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    useEffect(() => {
        if (week && year) {
            getWeekStartDate(week, year);
        }

        let storedColor = localStorage.getItem('avatarColor');
        if (!storedColor) {
            storedColor = getRandomColor();
            localStorage.setItem('avatarColor', storedColor);
        }
        setAvatarColor(storedColor);
    }, []);

    return (
        <div className="header">
            <div className='form'>
                <TextField
                    label="Week"
                    type="number"
                    size="small"
                    value={week}
                    onChange={(e) => setWeek(parseInt(e.target.value, 10))}
                    style={{margin: '15px', width: '30%'}}
                    inputProps={{min: 1, max:  52}}
                />
                <TextField
                    label="Year"
                    type="number"
                    size="small"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value, 10))}
                    style={{margin: '15px', width: '30%'}}
                    inputProps={{min: 2024}}
                />
                <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    style={{margin: '17px', width: '17%', borderColor: '#b4b4b4', color: '#e39865'}}
                >
                    Submit
                </Button>
            </div>
            <div className='link' style={{textDecoration: 'none'}}>
                {isMobile ? (
                    <div style={{marginRight: 50}}>
                        <Tooltip title="">
                            <IconButton onClick={handleClick}>
                                <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
                                    {getInitials()}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker' && 'focused'}`}>Resource Tracker</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/table?year=${year}&week=${week}`} className={`rt ${location.pathname === '/table' && 'focused'}`}>Data Table</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/workers?year=${year}&week=${week}`} className={`rt ${location.pathname === '/workers' && 'focused'}`}>Workers</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/graph?year=${year}&week=${week}`} className={`rt ${location.pathname === '/graph' && 'focused'}`}>Graphs</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/maps?year=${year}&week=${week}`} className={`rt ${location.pathname === '/maps' && 'focused'}`}>Maps</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/on-call?year=${year}&week=${week}`} className={`rt ${location.pathname === '/onCall' && 'focused'}`}>On Call</Link>
                            </MenuItem>
                            <MenuItem
                                sx={{width: 200, margin: '0 18px'}} 
                                onClick={() => { handleClose(); logOut(); }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <>
                        <Link to={`/pace-team/resourcing/resource-tracker?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker' && 'focused'}`}>Resource Tracker</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/table?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/table' && 'focused'}`}>Data Table</Link>
                        {/* <Link to={`/pace-team/resourcing/resource-tracker/workers?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/workers' && 'focused'}`}>Workers</Link> */}
                        {/* <Link to={`/pace-team/resourcing/resource-tracker/graph?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/graph' && 'focused'}`}>Graphs</Link> */}
                        {/* <Link to={`/pace-team/resourcing/resource-tracker/maps?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/maps' && 'focused'}`}>Maps</Link> */}
                        {/* <Link to={`/pace-team/resourcing/resource-tracker/on-call?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/on-call' && 'focused'}`}>On call</Link> */}
                        <div
                            className='rt'
                            onClick={handleClickStaff}
                        >
                            Staff <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElStaff}
                            open={openStaff}
                            onClose={handleCloseStaff}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem><Link to={`/pace-team/resourcing/staff-details`} className='dropdown' onClick={handleCloseStaff}>Staff Details</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resourcing/resource-tracker/workers?year=${year}&week=${week}`} className='dropdown' onClick={handleCloseStaff}>Workers Week {week}</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resourcing/workforce-map`} className='dropdown' onClick={handleCloseStaff}>Workforce Map</Link></MenuItem>
                        </Menu>
                        <div
                            className='rt'
                            onClick={handleClickRTData}
                        >
                            RT Data <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElRTdata}
                            open={openRtData}
                            onClose={handleCloseRtData}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem><Link to={`/pace-team/collaborations/clients`} className='dropdown' onClick={handleCloseRtData}>Clients</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/collaborations/projects`} className='dropdown' onClick={handleCloseRtData}>Projects</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/rt-data`} className='dropdown' onClick={handleCloseRtData}>Other Data</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/payroll/payment-method`} className='dropdown' onClick={handleCloseMore}>Payment Methods</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resourcing/authorized-drivers`} className='dropdown' onClick={handleCloseMore}>Authorized Drivers</Link></MenuItem>
                        </Menu>
                        <div
                            className='rt'
                            onClick={handleClickSentinel}
                        >
                            Sentinel <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElSentinel}
                            open={openSentinel}
                            onClose={handleCloseSentinel}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem><Link to={`/pace-team/resourcing/resource-tracker/fatigue-check?year=${year}&week=${week}`} className='dropdown' onClick={handleCloseSentinel}>Fatigues</Link></MenuItem>
                            {/* <MenuItem><Link to={`/pace-team/roles/manage-roles`} className='dropdown' onClick={handleCloseSentinel}>Roles</Link></MenuItem> */}
                            <MenuItem><Link to={`/pace-team/competencies`} className='dropdown' onClick={handleCloseSentinel}>Competencies</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/experiences`} className='dropdown' onClick={handleCloseSentinel}>Experiences</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/tickets`} className='dropdown' onClick={handleCloseSentinel}>Tickets</Link></MenuItem>
                        </Menu>

                        <div
                            className='rt'
                            onClick={handleClickOnCall}
                        >
                            On Call <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElonCall}
                            open={openOnCall}
                            onClose={handleCloseOnCall}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem><Link to={`/pace-team/resourcing/resource-tracker/on-call?year=${year}&week=${week}`} className='dropdown' onClick={handleCloseOnCall}>On Call</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resourcing/live-tracker`} className='dropdown' onClick={handleCloseOnCall}>Live Tracker</Link></MenuItem>
                            <MenuItem><Link to={`https://railresourcing.pace-is.co.uk/pace-team/resource-tracker/location-logger`} target="_blank" rel="noopener noreferrer" className='dropdown' onClick={handleCloseOnCall}>Location Logger</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resource-tracker/signin`} className='dropdown' onClick={handleCloseOnCall}>Signin Sheet</Link></MenuItem>
                            <MenuItem><Link to={`/pace-team/resourcing/resource-tracker/graph?year=${year}&week=${week}`} className='dropdown' onClick={handleCloseOnCall}>Graphs</Link></MenuItem>
                        </Menu>
                        <div
                            className='rt'
                            onClick={handleClickMore}
                        >
                            Report <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElMore}
                            open={openMore}
                            onClose={handleCloseMore}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleGeneratePayrollReport}>Generate Payroll Report</MenuItem>
                            <MenuItem> <Link to={`/pace-team/resourcing/resource-tracker/generate-timesheet?year=${year}&week=${week}`} className='dropdown' onClick={handleCloseOnCall}>Generate Colas Timesheet</Link></MenuItem>
                        </Menu>
                        <Tooltip title="">
                            <IconButton onClick={handleClick}>
                                <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
                                    {getInitials()}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem
                                sx={{width: 200}} 
                                onClick={() => { handleClose(); logOut(); }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header;
